<template>
  <div class="template-1">
    <div
      class="section row align-items-center mx-0"
      :style="data.background_style || '#fff'"
    >
      <div class="col-6">
        <div class="text-center">
          <div class="img-container">
            <img class="img" :src="data.image_1 | convertBLOB" height="50px" />
            <div v-if="!data.image_1" class="centered">Image 1</div>
          </div>
          <h3 class="mt-2" :class="{ 'text-white': data.title }">
            {{ data.title || "Title" }}
          </h3>
          <h5 class="mt-2" :class="{ 'text-white': data.subTitle }">
            {{ data.subTitle || "SubTitle" }}
          </h5>
        </div>
      </div>
      <div class="col-6">
        <div class="img-container">
          <img
            width="100%"
            height="100px"
            class="img"
            :src="data.image_2 | convertBLOB"
          />
          <div v-if="!data.image_2" class="centered">Image 2</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Template-1",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  filters: {
    convertBLOB(blob) {
      let isBase64 = null;
      if (blob) {
        // base64 pattern check
        const pattern =
          /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
        isBase64 = pattern.test(blob);
      }
      return blob
        ? typeof blob === "string"
          ? isBase64
            ? `data:image/jpeg;base64,${blob}`
            : blob
          : URL.createObjectURL(blob)
        : "/img/image_placeholder.png";
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  min-height: 300px;
  background-color: transparent;
  // background-image: linear-gradient(130deg, #00b9de 0%, #1c3788 89%);
}
// .img{
//   object-fit: cover;
// }
.img-container {
  position: relative;
  text-align: center;
  color: #202020;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
